<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>

    <!-- reset password -->
    <ion-content v-if="requestPosted === false && tokenValid === true">
      <form @submit.prevent="resetPassword">
        <ion-grid>
          <ion-row justify-content-center>
            <ion-col size="10">
              <h1>Wachtwoord instellen</h1>
              <div :color="password.length < 8 ? 'danger' : 'success'" style="margin-right: 15px; display: flex;">
                <span style="margin-right: 15px;">
                  <ion-icon name="close" v-show="password.length < 8"></ion-icon>
                  <ion-icon name="checkmark" v-show="password.length >= 8"></ion-icon>
                </span>
                <span>Minimaal 8 characters</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10">
              <div :color="password === password.toLowerCase() ? 'danger' : 'success'" style="margin-right: 15px; display: flex;">
                <span style="margin-right: 15px;">
                  <ion-icon name="close" v-show="password === password.toLowerCase()"></ion-icon>
                  <ion-icon name="checkmark" v-show="password !== password.toLowerCase()"></ion-icon>
                </span>
                <span>Minimaal 1 hoofdletter</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10">
              <div :color="password === password.toUpperCase() ? 'danger' : 'success'" style="margin-right: 15px; display: flex;">
                <span style="margin-right: 15px;">
                  <ion-icon name="close" v-show="password === password.toUpperCase()"></ion-icon>
                  <ion-icon name="checkmark" v-show="password !== password.toUpperCase()"></ion-icon>
                </span>
                <span>Minimaal 1 kleine letter</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10">
              <div :color="!/\d/.test(password) ? 'danger' : 'success'" style="margin-right: 15px; display: flex;">
                <span style="margin-right: 15px;">
                  <ion-icon name="close" v-show="!/\d/.test(password)"></ion-icon>
                  <ion-icon name="checkmark" v-show="/\d/.test(password)"></ion-icon>
                </span>
                <span>Minimaal 1 cijfer</span>
              </div>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10">
              <ion-item v-if="passwordsNotMatching" color="danger" class="login_error">
                De wachtwoorden komen niet overeen.
              </ion-item>
              <ion-item v-else-if="passwordRequirements" color="danger" class="login_error">
                Het wachtwoord voldoet niet aan de eisen.
              </ion-item>
              <ion-item v-else-if="showError" color="danger" class="login_error">
                Herstellen van wachtwoord is mislukt.
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10">
              <ion-item>
                <ion-label position="stacked">Wachtwoord</ion-label>
                <ion-input type="password" id="password" name="password" ref="password" required="required" @ionChange="password = $event.target.value" value="" tabindex="1"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10">
              <ion-item>
                <ion-label position="stacked">Herhaal wachtwoord</ion-label>
                <ion-input type="password" id="password_repeat" name="password_repeat" required="required" ref="password_repeat" @ionChange="passwordRepeat = $event.target.value" value="" tabindex="2"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10">
              <ion-button type="submit" :disabled="requestButtonLoading" tabindex="4">Opslaan</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>

    <ion-content v-else-if="requestPosted === true && tokenValid === true">
      <ion-grid>
        <ion-row justify-content-center>
          <ion-col size="10">
            <h1>Wachtwoord ingesteld</h1>
            <p>Het nieuwe wachtwoord is ingesteld.</p>
          </ion-col>
        </ion-row>
        <ion-row justify-content-center>
          <ion-col size="10">
            <ion-button type="button" @click="$router.push({ name: 'login' })">Inloggen</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>

    <ion-content v-else>
      <ion-grid>
        <ion-row justify-content-center>
          <ion-col size="10">
            <h1>Link ongeldig</h1>
            <p>De activatielink is ongeldig of verlopen.</p>
          </ion-col>
        </ion-row>
        <ion-row justify-content-center>
          <ion-col size="10">
            <ion-button type="button" @click="$router.push({ name: 'forgot_password' })">Opnieuw proberen</ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  name: 'ResetPassword',
  data () {
    return {
      password: '',
      passwordRepeat: '',
      tokenValid: null,
      requestButtonDisabled: true,
      requestButtonLoading: false,
      requestPosted: false,
      showError: false,
      passwordsNotMatching: false,
      passwordRequirements: false
    }
  },
  methods: {
    async resetPassword () {
      if (this.password !== this.passwordRepeat) {
        this.passwordsNotMatching = true
        return false
      } else if (!(this.password.length >= 8 &&
        this.password !== this.password.toLowerCase() &&
        this.password !== this.password.toUpperCase() &&
        /\d/.test(this.password) &&
        this.password === this.passwordRepeat)) {
        this.passwordRequirements = true
        return false
      }

      this.requestButtonLoading = true
      this.showError = false

      let postData = {
        password: this.password
      }

      const resetPassword = await this.$axios.post(this.$axios.routes.reset_password + '/' + this.$route.params.token, postData)

      if (typeof resetPassword.data !== 'undefined' && typeof resetPassword.data.success !== 'undefined' && resetPassword.data.success === true) {
        this.requestPosted = true
      } else {
        this.showError = true
      }

      this.requestButtonLoading = false
    }
  },
  async mounted () {
    this.$axios.logout(false)

    if (typeof this.$route.params.token === 'undefined') {
      this.$router.push({ name: 'home' })
    } else {
      const resetLinkValid = await this.$axios.get(this.$axios.routes.reset_password + '/' + this.$route.params.token)

      this.tokenValid = typeof resetLinkValid.data !== 'undefined' && typeof resetLinkValid.data.success !== 'undefined' && resetLinkValid.data.success === true
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
