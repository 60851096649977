<template>
  <ion-list v-if="answers !== null">
    <ion-item no-padding @click="nextScreen(answer)" v-for="(answer, index) in answers" :key="index">
      <ion-text>
        {{ answer.answer }}
      </ion-text>
    </ion-item>
  </ion-list>
</template>

<script>
export default {
  name: 'ClockAnswerButtons',
  props: {
    answers: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  methods: {
    nextScreen (answer) {
      if (answer.to === 'screen') {
        const routerNameList = ['home', 'screen']
        const getHistoryFromRouterNameList = this.$store.state.routerHistory.filter(obj => routerNameList.includes(obj.name))
        const lastPage = getHistoryFromRouterNameList.pop()
        if (typeof lastPage === 'undefined') {
          this.$router.push({
            name: routerNameList[0]
          })
        } else {
          this.$router.push({
            name: lastPage.name,
            params: lastPage.params
          })
        }
      } else {
        this.$router.push({
          'name': 'clock',
          'params': {
            'id': answer.to
          }
        })
      }
    }
  }
}
</script>

<style type="scss" scoped>
  ion-list {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  ion-list ion-item {
    cursor: pointer;
    --background: #f9f9f9
  }
  ion-list ion-item:first-child {
    border-top: 1px solid #DEDEDE;
  }
  ion-list ion-item ion-text {
    padding-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>
