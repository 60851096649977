<template>
  <ion-grid @click="goToClock">
    <ion-row justify-content-center>
      <ion-col size="6" size-sm="5" size-md="4" size-lg="3">
        Tijd verstreken sinds onset
      </ion-col>
      <ion-col size="6" size-sm="5" size-md="4" size-lg="3">
        Tijd verstreken in ziekenhuis
      </ion-col>
    </ion-row>
    <ion-row justify-content-center>
      <ion-col size="6" size-sm="5" size-md="4" size-lg="3">
        <ion-text v-if="$store.state.clocks.time_first_occurence">
          <span v-if="timeSinceOnset[0] > 0">{{ timeSinceOnset[0] }}<small>d</small></span>
          {{ timeSinceOnset[1] }}<small>u</small>
          {{ timeSinceOnset[2] }}<small>m</small>
        </ion-text>
        <ion-text v-else-if="$store.state.clocks.time_last_good && $store.state.clocks.time_discovered">
          <span v-if="timeSinceLastGood[0] > 0">{{ timeSinceLastGood[0] }}<small>d</small></span>
          {{ timeSinceLastGood[1] }}<small>u</small>
          {{ timeSinceLastGood[2] }}<small>m</small>
        </ion-text>
        <ion-text v-else>-</ion-text>
      </ion-col>
      <ion-col size="6" size-sm="5" size-md="4" size-lg="3">
        <ion-text v-if="$store.state.clocks.time_arrival_hospital">
          <span v-if="timeArrivalHospital[0] > 0">{{ timeArrivalHospital[0] }}<small>d</small></span>
          {{ timeArrivalHospital[1] }}<small>u</small>
          {{ timeArrivalHospital[2] }}<small>m</small>
        </ion-text>
        <ion-text v-else>-</ion-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</template>

<script>
export default {
  name: 'timebar',
  data () {
    return {
      'now': new Date()
    }
  },
  computed: {
    timeSinceOnset () {
      if (this.$store.state.clocks.time_first_occurence) {
        let timeOnset = this.$moment(Date.parse(this.$store.state.clocks.time_first_occurence))
        let now = this.$moment(this.now)
        let diff = now.diff(timeOnset)
        let duration = this.$moment.duration(diff)
        return this.formatDuration(duration)
      } else {
        return null
      }
    },
    timeSinceLastGood () {
      if (this.$store.state.clocks.time_last_good && this.$store.state.clocks.time_discovered) {
        let timeLastGood = Date.parse(this.$store.state.clocks.time_last_good)
        let timeDiscovered = Date.parse(this.$store.state.clocks.time_discovered)
        let between = this.$moment(new Date((timeDiscovered + timeLastGood) / 2))

        let now = this.$moment(this.now)
        let diff = now.diff(between)
        let duration = this.$moment.duration(diff)
        return this.formatDuration(duration)
      } else {
        return null
      }
    },
    timeArrivalHospital () {
      if (this.$store.state.clocks.time_arrival_hospital) {
        let timeArrival = this.$moment(Date.parse(this.$store.state.clocks.time_arrival_hospital))
        let now = this.$moment(this.now)
        let diff = now.diff(timeArrival)
        let duration = this.$moment.duration(diff)
        return this.formatDuration(duration)
      } else {
        return null
      }
    }
  },
  methods: {
    formatDuration: function (duration) {
      let days = duration.get('days')
      let hours = Math.floor(duration.get('hours') % 24)
      let minutes = Math.floor(duration.get('minutes') % 60)
      return [days, hours, minutes]
    },
    goToClock () {
      if (this.$route.name !== 'clock') {
        this.$ionic.alertController.dismiss()
        this.$ionic.alertController
          .create({
            header: 'Klok instellen?',
            message: 'Wil je naar de tab om de klok in te stellen?',
            buttons: [
              {
                text: 'Ja, naar klok',
                handler: () => {
                  const getHistoryFromRouter = this.$store.state.routerHistory.filter(obj => obj.name === 'clock')
                  const lastPage = getHistoryFromRouter.pop()
                  if (typeof lastPage === 'undefined') {
                    this.$router.push({ name: 'clock' })
                  } else {
                    this.$router.push({
                      name: lastPage.name,
                      params: lastPage.params
                    })
                  }
                }
              },
              {
                text: 'Nee, doorgaan',
                role: 'cancel',
                cssClass: 'secondary'
              }
            ]
          })
          .then(a => a.present())
      }
    }
  },
  mounted () {
    setInterval(() => {
      this.now = new Date()
    }, 3000)
  }
}
</script>

<style type="scss" scoped>
  ion-col {
    text-align: center;
    color: #747475;
    --ion-grid-column-padding: 5px;
  }
  ion-row:last-child {
    font-size: 1.8rem;
  }
</style>
