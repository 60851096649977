<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1>FABS</h1>
          </ion-col>
          <ion-col><ion-button size="small" @click="$router.push({ name: 'scales' })">Terug naar schalen</ion-button></ion-col>
        </ion-row>
      </ion-grid>
      <ion-list>
        <ion-item>
          <ion-label>Geen faciale parese</ion-label>
          <ion-toggle
            @ionChange="calculateTotal($event.target)" value="1">
          </ion-toggle>
        </ion-item>
        <ion-item>
          <ion-label>Leeftijd &lt; 50 jaar</ion-label>
          <ion-toggle
            @ionChange="calculateTotal($event.target)" value="1">
          </ion-toggle>
        </ion-item>
        <ion-item>
          <ion-label>Systole &lt; 150 mmHg</ion-label>
          <ion-toggle
            @ionChange="calculateTotal($event.target)" value="1">
          </ion-toggle>
        </ion-item>
        <ion-item>
          <ion-label><strong>Geen</strong> AF in voorgeschiedenis</ion-label>
          <ion-toggle
            @ionChange="calculateTotal($event.target)" value="1">
          </ion-toggle>
        </ion-item>
        <ion-item>
          <ion-label>Alleen sensorische klachten, geen motorische klachten</ion-label>
          <ion-toggle
            @ionChange="calculateTotal($event.target)" value="1">
          </ion-toggle>
        </ion-item>
        <ion-item>
          <ion-label>Insulten in de voorgeschiedenis</ion-label>
          <ion-toggle
            @ionChange="calculateTotal($event.target)" value="1">
          </ion-toggle>
        </ion-item>
        <ion-item id="total">
          TOTAAL FABS: {{ total }} punt<span v-if="total !== 1">en</span>
        </ion-item>
      </ion-list>
      <p>
        Waarschijnlijkheid stroke mimic [PROB] uit 1.00<br /><br />
        Berekening [PROB]:
      </p>
      <ion-grid>
        <ion-row id="header">
          <ion-col>
            [TOTAAL]
          </ion-col>
          <ion-col>
            [PROB]
          </ion-col>
        </ion-row>
        <ion-row :class="{ 'backgroundActive': total === 0 }">
          <ion-col>
            0
          </ion-col>
          <ion-col>
            0.00
          </ion-col>
        </ion-row>
        <ion-row :class="{ 'backgroundActive': total === 1 }">
          <ion-col>
            1
          </ion-col>
          <ion-col>
            0.00
          </ion-col>
        </ion-row>
        <ion-row :class="{ 'backgroundActive': total === 2 }">
          <ion-col>
            2
          </ion-col>
          <ion-col>
            0.14
          </ion-col>
        </ion-row>
        <ion-row :class="{ 'backgroundActive': total === 3 }">
          <ion-col>
            3
          </ion-col>
          <ion-col>
            0.80
          </ion-col>
        </ion-row>
        <ion-row :class="{ 'backgroundActive': total === 4 }">
          <ion-col>
            4
          </ion-col>
          <ion-col>
            0.94
          </ion-col>
        </ion-row>
        <ion-row :class="{ 'backgroundActive': total === 5 }">
          <ion-col>
            5
          </ion-col>
          <ion-col>
            1.00
          </ion-col>
        </ion-row>
        <ion-row :class="{ 'backgroundActive': total === 6 }">
          <ion-col>
            6
          </ion-col>
          <ion-col>
            1.00
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  name: 'home',
  data () {
    return {
      total: 0
    }
  },
  methods: {
    calculateTotal (eventTarget) {
      if (eventTarget.checked) {
        this.total = this.total + parseInt(eventTarget.value)
      } else {
        this.total = this.total - parseInt(eventTarget.value)
      }
    }
  }
}
</script>

<style type="scss" scoped>
  #total {
    --background: #f1f1f1;
    font-weight: bold;
  }
  ion-grid:first-child {
    --ion-grid-padding: 0px;
  }
  ion-grid:first-child ion-row ion-col:last-child {
    text-align: right
  }
  ion-item {
    --inner-padding-start: 10px;
    --padding-start: 0;
  }
  ion-grid:last-child {
    --ion-grid-padding: 10px;
  }
  ion-grid:last-child #header {
    border-top: 1px solid #f1f1f1;
    font-weight: bold;
  }
  ion-grid:last-child ion-row {
    border-left: 1px solid #f1f1f1;
    border-right: 1px solid #f1f1f1;
    border-bottom: 1px solid #f1f1f1;
    visibility: visible !important;
  }
  .backgroundActive {
    background-color: #f1f1f1;
  }
</style>
