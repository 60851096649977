<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>
    <ion-content>
      <ion-grid>
        <ion-row>
          <ion-col>
            <h1>Disclaimer</h1>
            <p>
              Hoewel aan de samenstelling van deze applicatie de grootst mogelijke zorg is besteed, kunnen de samenstellers niet aansprakelijk worden gesteld voor eventuele fouten, noch kunnen aan de inhoud rechten worden ontleend.
            </p>
            <p>
              De lokale actuele protocollen zijn leidend!
            </p>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <ion-item>
              <ion-label>Accepteer de voorwaarden</ion-label>
              <ion-checkbox type="checkbox" slot="start" :checked='acceptDisclaimer' @ionChange="toggleDisclaimerAccept" color="primary"></ion-checkbox>
            </ion-item>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <ion-button :disabled="!acceptDisclaimer" expand="full" @click="acceptDisclaimerButton">
        Verder
      </ion-button>
    </ion-footer>
  </ion-page>
</template>

<script>
export default {
  name: 'home',
  data () {
    return {
      'acceptDisclaimer': false
    }
  },
  methods: {
    acceptDisclaimerButton () {
      if (this.acceptDisclaimer === true) {
        this.$store.commit('acceptDisclaimer', { 'accepted': true })
        this.$router.push({
          name: 'screen',
          params: {
            'id': Object.keys(this.$store.state.screens)[0]
          }
        })
      }
    },
    toggleDisclaimerAccept () {
      this.acceptDisclaimer = !this.acceptDisclaimer
    }
  }
}
</script>

<style type="scss" scoped>
  ion-grid {
    height: 100%;
    display: flex;
    flex-flow: column;
  }
  ion-grid ion-row {
    --ion-grid-column-padding: 0;
  }
  ion-grid ion-row:first-child {
    flex-grow: 1;
  }
  ion-grid ion-row ion-item {
    --border-style: 0;
    --background: #fff;
    --background-activated: #fff;
    --background-focused: #fff;
    --background-hover: #fff;
    --ripple-color: #9c9c9c;
  }
  ion-grid ion-row ion-item ion-checkbox {
    --background: #fff;
    --background-checked: #545454;
    --border-color: #545454;
    --border-color-checked: #545454;
    --checkmark-color: #fff;
    --size: 25px;
  }
</style>
