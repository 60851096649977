<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>
    <ion-content v-if="clock_screen !== null">
      <ion-grid>
        <ion-row align-items-start>
          <ion-col>
            <h1>{{ clock_screen.title}}</h1>
            <component v-if="clock_screen.answers" :answers="clock_screen.answers" :is="compiledScreen"></component>
            <component v-if="clock_screen.clocks" :to="clock_screen.to" :clocks="clock_screen.clocks" :is="compiledClockScreen"></component>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <umcg-timebar />
    </ion-footer>
  </ion-page>
</template>

<script>
export default {
  name: 'clock_screen',
  data () {
    return {
      'clock_screen': null
    }
  },
  methods: {
    restartScreens () {
      this.$router.push({
        name: 'clock',
        params: { 'id': 1 }
      })
    }
  },
  computed: {
    compiledScreen () {
      return {
        props: ['answers'],
        template: `<div>${this.clock_screen.description}</div>`
      }
    },
    compiledClockScreen () {
      return {
        props: ['clocks', 'to'],
        template: `<div>${this.clock_screen.description}</div>`
      }
    }
  },
  watch: {
    $route (to, from) {
      let routeId = '1'
      if (to.params.id) {
        routeId = to.params.id
      }
      if (to.name === 'clock' && (typeof to.params.id === 'undefined' || to.params.id.toString() === '1')) {
        this.$store.commit('clearClocks')
      }
      if (typeof this.$store.state.clock_screens[routeId] !== 'undefined') {
        this.clock_screen = this.$store.state.clock_screens[routeId.toString()]
      }
    }
  },
  mounted () {
    if (this.$route.name === 'clock' && (typeof this.$route.params.id === 'undefined' || this.$route.params.id.toString() === '1')) {
      this.$store.commit('clearClocks')
    }
    let routeId = '1'
    if (this.$route.params.id) {
      routeId = this.$route.params.id
    }
    if (typeof this.$store.state.clock_screens[routeId] !== 'undefined') {
      this.clock_screen = this.$store.state.clock_screens[routeId.toString()]
    }
  }
}
</script>

<style type="scss" scoped>
  div {
    padding: 0;
  }
  ion-grid {
    --ion-grid-padding: 0;
  }
  ion-grid ion-row ion-col {
    --ion-grid-column-padding: 0;
  }
</style>
