<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>
    <ion-content>
      <ion-grid>
        <ion-row align-items-start>
          <ion-col>
            <h1>CVA beslishulp</h1>
            <p>
              Welkom bij de CVA beslishulp.
            </p>
            <h2>Medische informatie</h2>
            <p>
              IAT overleglijn (tijdens kantooruren): 050 361 1468<br />
              Dr. S. Akoudad (06 25650959), vasculair neuroloog<br />
              Dr. M. Uyttenboogaart (06 22245460), interventie neuroloog
            </p>
            <h2>App informatie</h2>
            <p>
              Gert Messchendorp (06 25650775), verpleegkundig specialist AGZ (vasculaire neurologie)
            </p>
          </ion-col>
        </ion-row>
        <ion-row align-items-end>
          <ion-col>
            <ion-button expand="full" color="primary" @click="startScreens">
              Starten
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-footer>
      <umcg-timebar />
    </ion-footer>
  </ion-page>
</template>

<script>
export default {
  name: 'home',
  methods: {
    startScreens () {
      this.$router.push(
        { name: 'screen',
          params: {
            id: Object.keys(this.$store.state.screens)[0]
          }
        }
      )
    }
  }
}
</script>

<style type="scss" scoped>
  ion-grid {
    height: 100%;
    display: flex;
    flex-flow: column;
    --ion-grid-padding: 0;
  }
  ion-grid ion-row:first-child {
    flex-grow: 1;
  }
  ion-grid ion-row ion-col {
    --ion-grid-column-padding: 0;
  }
</style>
