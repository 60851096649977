import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  supportCircular: true,
  storage: window.localStorage
})

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    access_token: null,
    user: {},
    routerHistory: [],
    clock_screens: {},
    screens: {},
    disclaimer: {},
    clocks: {}
  },
  mutations: {
    setAccessToken (state, accessToken) {
      state.access_token = accessToken
    },
    setUser (state, user) {
      state.user = user
    },
    addItemToRouterHistory (state, item) {
      state.routerHistory.push(item)
    },
    removeItemFromRouterHistory (state, item) {
      const indexInReverse = state.routerHistory.slice().reverse().findIndex(obj => obj.path === item)
      const indexInOriginal = state.routerHistory.length - 1 - indexInReverse
      state.routerHistory.splice(indexInOriginal, 1)
    },
    removeItemsFromRouterHistoryBasedOnName (state, item) {
      state.routerHistory = state.routerHistory.filter(obj => obj.name !== item)
    },
    clearRouterHistory (state) {
      state.routerHistory = []
    },
    setScreens (state, screens) {
      state.screens = screens
    },
    setClockScreens (state, screens) {
      state.clock_screens = screens
    },
    acceptDisclaimer (state, accepted) {
      state.disclaimer = accepted
    },
    setLogin (state, loginInfo) {
      state.login = loginInfo
    },
    setLogout (state) {
      state.login = {}
    },
    setClock (state, clockInfo) {
      Vue.set(state.clocks, clockInfo.id, clockInfo.value)
    },
    clearClocks (state) {
      state.clocks = {}
    }
  },
  getters: {
    getAccessToken: state => {
      return state.access_token
    },
    getUser: state => {
      if (Object.keys(state.user).length > 0) {
        return state.user
      } else {
        return false
      }
    }
  }
})
