<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>

    <!-- login form -->
    <ion-content v-if="!mfaRequired">
        <form @submit.prevent="validateLogin">
          <ion-grid>
            <ion-row justify-content-center>
              <ion-col size="10">
                <h1>Login</h1>
                <ion-item v-if="showError" color="danger" class="login_error">
                  Gebruikersnaam of wachtwoord is onjuist.
                </ion-item>
                <ion-item v-else-if="showBlockedError" color="danger" class="login_error">
                  Gebruiker is geblokkeerd.
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row justify-content-center>
              <ion-col size="10">
                <ion-item>
                  <ion-label position="stacked">Gebruikersnaam</ion-label>
                  <ion-input type="email" border="danger" name="username" @ionChange="username = $event.target.value"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row justify-content-center>
              <ion-col size="10">
                <ion-item>
                  <ion-label position="stacked">Wachtwoord</ion-label>
                  <ion-input type="password" name="password" @ionChange="password = $event.target.value"></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>
            <ion-row justify-content-center>
              <ion-col size="10" style="width: 100%; text-align: right;">
                <span @click="$router.push({ name: 'forgot_password' })" style="text-decoration: underline;">Wachtwoord vergeten?</span>
              </ion-col>
            </ion-row>
            <ion-row justify-content-center>
              <ion-col size="10" style="--ion-grid-column-padding: 20px;">
                <ion-button type="submit" :disabled="loginButtonLoading || loginButtonDisabled">Login</ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </form>
    </ion-content>

    <!-- MFA setup -->
    <ion-content v-else>
      <form class="d-flex flex-column" method="post" @submit.prevent="validateMFA">
        <ion-grid>
          <ion-row justify-content-center>
            <ion-col size="10">
              <h1 v-if="mfaQR">Two-factor authenticatie instellen</h1>
              <h1 v-else>Two-factor authenticatie code</h1>
              <temmplate v-if="!isMobile">
                <!-- text and QR -->
                <p v-if="mfaQR">Om two factor authenticatie te activeren, scan je de QR-code met uw authenticator-app. Als u er geen hebt, raden we aan <a href="https://www.microsoft.com/nl-nl/security/mobile-authenticator-app" target="_blank" class="text-decoration">Microsoft Authenticator</a> of <a href="https://support.google.com/accounts/answer/1066447?hl=nl&co=GENIE.Platform%3DAndroid" target="_blank" class="text-decoration">Google Authenticator</a> te gebruiken.</p>
                <img v-if="mfaQR" :src="mfaQR" class="align-self-center mb-2" alt="QR" />
              </temmplate>
              <template v-else>
                <p v-if="mfaQR">Zorg dat de authenticator-app op uw mobiel is geinstalleerd. Als u er geen hebt, raden we aan <a href="https://www.microsoft.com/nl-nl/security/mobile-authenticator-app" target="_blank" class="text-decoration">Microsoft Authenticator</a> of <a href="https://support.google.com/accounts/answer/1066447?hl=nl&co=GENIE.Platform%3DAndroid" target="_blank" class="text-decoration">Google Authenticator</a> te gebruiken.</p>
                <p v-if="mfaQR && $root.isAndroid">Als de app is geinstalleerd, klik <span style="text-decoration: underline;" @click="openAuthenticatorApp">hier</span> om de code in de authenticator-app te zetten.</p>
              </template>
              <!-- MFA secret -->
              <p v-if="mfaSecret && isMobile">Als u een iPhone gebruikt, problemen hebt met openen van de app of de code handmatig wilt invullen, gebruik dan de code:</p>
              <p v-else-if="mfaSecret && !isMobile">Voer na het scannen de zescijferige code van uw authenticator-app in om de setup te verifiëren. Als u problemen hebt met scannen of de code handmatig wilt invullen, gebruik dan de code:</p>
              <!-- copy MFA secret -->
              <ion-item v-if="mfaSecret" class="d-flex justify-content-center mb-3">
                <ion-input ref="MFASecret" type="text" readonly="readonly" :value="mfaSecret"></ion-input>
                <!--<ion-icon name="copy" @click="copySecret"></ion-icon>-->
              </ion-item>
              <div v-if="mfaQR" style="margin-top: 20px; font-weight: bold;">
                Hulp nodig met handmatig invullen?
              </div>
              <div v-if="mfaQR" style="padding: 10px; margin-top: 20px; border-bottom: 1px solid gray;" @click="showDescriptionAuthenticator === null || showDescriptionAuthenticator === 'google' ? showDescriptionAuthenticator = 'microsoft' : showDescriptionAuthenticator = null">
                Hoe voeg ik de code handmatig toe bij Microsoft Authenticator?
              </div>
              <p v-if="mfaQR && showDescriptionAuthenticator === 'microsoft'">
                Voer de volgende stappen uit om Two-factor authentication te activeren voor Microsoft Authenticator.
              <ul>
                <li>Kopieer de bovenstaande code</li>
                <li>Open de Microsoft Authenticator app</li>
                <li>Klik rechtsboven op de 3 puntjes of het '+' symbool en kies 'Account toevoegen'</li>
                <li>Klik bij het soort account op 'Ander account' of 'overig'</li>
                <li>Klik onderin je scherm op 'Of voer de code handmatig in'</li>
                <li>Bij 'Accountnaam', vul in: Beslishulp CVA</li>
                <li>Bij 'Geheime sleutel', plak de gekopieerde bovenstaande sleutel</li>
                <li>Klik op 'Voltooien'</li>
              </ul>
              </p>
              <div v-if="mfaQR" style="padding: 10px; border-bottom: 1px solid gray;" @click="showDescriptionAuthenticator === null || showDescriptionAuthenticator === 'microsoft' ? showDescriptionAuthenticator = 'google' : showDescriptionAuthenticator = null">
                Hoe voeg ik de code handmatig toe bij Google Authenticator?
              </div>
              <p v-if="mfaQR && showDescriptionAuthenticator === 'google'">
                Voer de volgende stappen uit om Two-factor authentication te activeren voor Google Authenticator.
                <ul>
                  <li>Kopieer de bovenstaande code</li>
                  <li>Open de Google Authenticator app</li>
                  <li>Klik rechtsonder op het '+' symbool en kies 'Instelsleutel invullen'</li>
                  <li>Bij 'Account', vul in: Beslishulp CVA</li>
                  <li>Bij 'Sleutel', plak de gekopieerde bovenstaande sleutel</li>
                  <li>Klik op 'Toevoegen'</li>
                </ul>
              </p>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10">
              <p>Voer de zescijferige code van uw authenticator-app in.</p>
              <ion-item color="danger" class="login_error" v-show="showMfaError">
                De code is onjuist. De code heeft een korte geldigheidsduur waardoor het kan zijn dat de code te laat is ingevoerd. Probeer het nogmaals.
              </ion-item>
              <ion-item>
                <ion-label position="stacked">Code</ion-label>
                <ion-input value="" type="number" name="mfa" @ionChange="mfaCode = $event.target.value" tabindex="1" pattern="[0-9]*" inputmode="numeric"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10" style="--ion-grid-column-padding: 20px;">
              <ion-button @click="cancelMFA()" type="button">Annuleren</ion-button>
              <ion-button type="submit" :disabled="mfaButtonDisabled" v-if="mfaButtonLoading === false" tabindex="5">Inloggen</ion-button>
              <ion-button type="submit" disabled="disabled" v-else>Inloggen</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
import qrcode from 'qrcode'
import { authenticator } from 'otplib'

export default {
  name: 'login',
  data () {
    return {
      isMobile: true,
      username: '',
      password: '',
      loginButtonLoading: false,
      loginButtonDisabled: true,
      mfaButtonLoading: false,
      mfaButtonDisabled: true,
      showError: false,
      showBlockedError: false,
      mfaRequired: false,
      mfaSecret: null,
      mfaQR: null,
      mfaCode: '',
      showMfaError: false,
      showDescriptionAuthenticator: null
    }
  },
  methods: {
    clickCallback () {
      // Show the prompt
      this.$root.deferredPrompt.prompt()
      // Wait for the user to respond to the prompt
      this.$root.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          // Call another function?
        }
        this.$root.deferredPrompt = null
      })
    },
    openAuthenticatorApp () {
      window.location.href = 'otpauth://totp/Beslishulp%20CVA:' + this.username + '?secret=' + this.mfaSecret + '&issuer=Beslishulp%20CVA&digits=6&period=30'
    },
    async cancelMFA () {
      this.$axios.logout(false)
      Object.assign(this.$data, this.$options.data.call(this))
      this.checkPWA()
    },
    async validateMFA () {
      this.showMfaError = false

      let auth
      if (this.mfaSecret) {
        auth = await this.$axios.setup_mfa(this.mfaSecret, this.mfaCode)
      } else {
        auth = await this.$axios.validate_mfa(this.mfaCode)
      }
      if (typeof auth.status !== 'undefined' && auth.status === 200) {
        this.$router.push({ name: 'home' })
      } else {
        this.showMfaError = true
      }
    },
    async validateLogin () {
      this.loginButtonLoading = true
      this.showError = false

      const auth = await this.$axios.login(this.username, this.password)

      if (typeof auth.status !== 'undefined' && auth.status === 200) {
        // MFA needs to be setup
        if (auth.data.secret) {
          const otpauth = authenticator.keyuri(this.username, process.env.VUE_APP_TITLE, auth.data.secret)
          qrcode.toDataURL(otpauth, (err, imageUrl) => {
            if (err) {
              console.log('Error with QR')
              return
            }
            this.mfaRequired = true
            this.mfaSecret = auth.data.secret
            this.mfaQR = imageUrl
          })
        } else {
          this.loginButtonLoading = false
          if (!auth.data.mfa_required) {
            await this.$axios.getUser()
            this.$router.push({ name: 'home' })
          } else {
            this.mfaRequired = true
          }
        }
      } else if (typeof auth.data !== 'undefined' && typeof auth.data.error_code !== 'undefined' && auth.data.error_code.includes('USER_BLOCKED')) {
        this.showBlockedError = true
        this.loginButtonLoading = false
      } else {
        this.showError = true
        this.loginButtonLoading = false
      }
    },
    copySecret () {
      let copyText = this.$refs.MFASecret

      copyText.select()
      copyText.setSelectionRange(0, 99999) /* For mobile devices */

      document.execCommand('copy')
    }
  },
  computed: {
    watchUserPassInputs () {
      return [this.username, this.password]
    }
  },
  watch: {
    watchUserPassInputs () {
      this.loginButtonDisabled = !(this.username !== '' && this.password !== '')
    },
    mfaCode () {
      this.mfaButtonDisabled = !(this.mfaCode !== '')
    }
  },
  mounted () {
  }
}
</script>

<style type="scss" scoped>
  ion-item {
    --padding-start: 0;
    --inner-padding-start: 10px;
  }
  ion-item.login_error {
    --inner-padding-top: 5px;
    --inner-padding-bottom: 5px;
  }
</style>
