<template>
  <a href="#" @click.prevent="openPdfAttachment"><slot /></a>
</template>

<script>
import PdfDocument from '../components/Pdf.vue'

export default {
  name: 'PdfModal',
  props: {
    name: String,
    attachment: String
  },
  methods: {
    openPdfAttachment: async function () {
      const modal = await this.$ionic.modalController.create({
        component: PdfDocument,
        componentProps: {
          propsData: {
            name: this.name,
            attachment: this.attachment
          }
        },
        cssClass: 'modal-fullscreen'
      })
      await modal.present()
    }
  }
}
</script>

<style type="scss" scoped>

</style>
