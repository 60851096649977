<template>
  <ion-page class="ion-page">
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ name }}</ion-title>
        <ion-buttons slot='end'>
          <ion-button @click="$ionic.modalController.dismiss()">
            <ion-icon color="primary" name="close"></ion-icon>
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-if="numPages" id="pdf-zoom" ref="pdfZoom">
        <div class="pdf-page" v-for="i in numPages" :key="i">
          <vue-pdf-embed
            v-if="src"
            :annotationLayer="true"
            :textLayer="true"
            :source="src"
            :page="i"
            class="pdf"
            @rendered="startZoomEvent"
          ></vue-pdf-embed>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import Pdf from '@teckel/vue-pdf'
import panzoom from 'panzoom'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

export default {
  name: 'Pdf',
  props: {
    name: String,
    attachment: String
  },
  data () {
    return {
      src: 'attachments/' + this.attachment,
      srcTask: Pdf.createLoadingTask('attachments/' + this.attachment),
      numPages: 0,
      panzoom: null
    }
  },
  components: {
    VuePdfEmbed
  },
  methods: {
    startZoomEvent () {
      this.panzoom = panzoom(this.$refs.pdfZoom, {
        maxZoom: 4,
        minZoom: 1,
        bounds: true,
        boundsPadding: 1,
        beforeWheel: function (e) {
          return !e.altKey
        },
        onClick: function (e) {
          if (e.target.tagName === 'A') {
            window.open(e.target.href)
            e.preventDefault()
          }
        }
      })
    }
  },
  mounted () {
    this.srcTask.promise.then(pdf => {
      this.numPages = pdf.numPages
    })
  }
}
</script>

<style type="scss" scoped>
ion-button ion-icon {
  font-size: 30px !important;
}
ion-content {
  --padding-start: 0;
  --padding-end: 0;
  --overflow: hidden;
}
#pdf-zoom {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
#pdf-zoom div {
  padding: 0 !important;
}
.pdf-page {
  display: flex;
  border-bottom: 5px solid gray;
  margin: 0;
  padding: 0;
  width: 100%;
}
</style>

<style>
.pdf {
  width: 100%;
  padding: 0;
}
.pdf div {
  padding: 0 !important;
}
.vue-pdf-embed__page {
  padding: 0 !important;
}
</style>
