<template>
  <ion-app>
    <!-- router -->
    <ion-vue-router v-if="isPWA || ['forgot_password', 'reset_password'].includes($route.name)" />

    <!-- install app -->
    <ion-content v-else>
      <custom-header></custom-header>
      <ion-grid>
        <ion-row justify-content-center>
          <ion-col size="10">
            <h1>App installeren</h1>
            <p v-if="$root.isIOS || $root.isAndroid">
              Om deze app te kunnen gebruiken, dient deze eerst te worden geïnstalleerd.
            </p>
            <div style="margin: 0; padding: 0;" v-if="$root.isIOS">
              <p v-if="!$root.isSafari">
                <span>
                  Om de app te kunnen installeren, dient deze geopend te worden in Safari.
                </span>
              </p>
              <p v-else>
                <span>Druk op de "Delen" knop en kies vervolgens voor "Zet op beginscherm". U kunt dit als volgt doen:</span>
                <strong style="display: block; margin-top: 25px; margin-bottom: 15px;">Stap 1: open het "Delen" scherm</strong>
                <img class="img-responsive" src="/img/install-ios-1.png" alt="Installeren op iOS: Stap 1" />
                <strong style="display: block; margin-top: 25px; margin-bottom: 15px;">Stap 2: kies "Zet op beginscherm"</strong>
                <span>Opent u de link vanuit een mail app? Dan kan het zijn dat deze optie niet beschikbaar is. Kopieer en plak de link dan handmatig in Safari.</span>
                <img style="margin-top: 15px;" class="img-responsive" src="/img/install-ios-2.png" alt="Installeren op iOS: Stap 2" />
                <strong style="display: block; margin-top: 25px; margin-bottom: 15px;">Stap 3: Kies "Voeg toe"</strong>
                <img class="img-responsive" src="/img/install-ios-3.png" alt="Installeren op iOS: Stap 3" />
                <strong style="display: block; margin-top: 25px; margin-bottom: 15px;">Stap 4: De app "Beslishulp CVA" is nu geïnstalleerd!</strong>
              </p>

              <p v-if="$root.isSafari">
                U kunt dit scherm nu sluiten en verder gaan in de geïnstalleerd app.
              </p>
            </div>
            <div style="margin: 0; padding: 0;" v-else-if="$root.isAndroid">
              <span>Klik op de drie puntjes rechtsbovenin en kies "Toevoegen aan startscherm" of "App installeren" uit de lijst. U kunt dit als volgt doen:</span>
              <strong style="display: block; margin-top: 25px; margin-bottom: 15px;">Stap 1: open het menu</strong>
              <img class="img-responsive" src="/img/install-android-1.png" alt="Installeren op Android: Stap 1" />
              <strong style="display: block; margin-top: 25px; margin-bottom: 15px;">Stap 2: kies "Toevoegen aan startscherm" of "App installeren"</strong><br />
              <img class="img-responsive" src="/img/install-android-2.png" alt="Installeren op Android: Stap 2" />
              <strong style="display: block; margin-top: 25px; margin-bottom: 15px;">Stap 3: Kies "Toevoegen"</strong><br />
              <img class="img-responsive" src="/img/install-android-3.png" alt="Installeren op Android: Stap 3" />
              <strong style="display: block; margin-top: 25px; margin-bottom: 15px;">Stap 4: De app "Beslishulp CVA" is nu geïnstalleerd!</strong>

                U kunt dit scherm nu sluiten en verder gaan in de geïnstalleerd app.
            </div>
            <div style="margin: 0; padding: 0;" v-else>
              Deze app is alleen geschikt voor iOS en Android. Open de app in Safari op iOS of in Chrome op Android.
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-app>
</template>

<script>
import update from './mixins/update'
export default {
  name: 'App',
  data () {
    return {
      isPWA: false
    }
  },
  methods: {
    checkPWA () {
      if (process.env.NODE_ENV === 'development') {
        this.isPWA = true
      } else {
        this.isPWA = window.matchMedia('(display-mode: standalone)').matches
        if (!this.isPWA) {
          setTimeout(this.checkPWA, 250)
        }
      }
    }
  },
  mixins: [update],
  mounted () {
    this.checkPWA()
  }
}
</script>

<style lang="scss" scoped>

</style>
