<template>
  <ion-list>
    <ion-item v-for="(clock, index) in clocks" :key="index">
      <ion-label position="stacked">{{ clock.label }}</ion-label>
      <ion-datetime @ionChange="handleChange(clock, $event.target.value)" :placeholder="clock.placeholder" display-format="DD MMMM YYYY HH:mm" :month-names="month_names" :min="minDate(clock)" :max="max_date" cancel-text="Sluiten" done-text="Opslaan"></ion-datetime>
    </ion-item>
  </ion-list>
</template>

<script>
export default {
  name: 'ClockAnswerClocks',
  data () {
    return {
      'clock_values': {},
      'min_date': new Date(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).setDate(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).getDate() - 2)).toISOString(),
      'max_date': (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1),
      'month_names': 'januari, februari, maart, april, mei, juni, juli, augustus, september, oktober, november, december'
    }
  },
  props: ['to', 'clocks'],
  methods: {
    handleChange (clock, value) {
      let dateValue = new Date(value)
      dateValue.setSeconds(0)
      let now = new Date().getTime()
      if (dateValue.getTime() > now) {
        this.$ionic.alertController.dismiss()
        this.$ionic.alertController
          .create({
            header: 'Ongeldige datum',
            message: 'De gekozen datum moet in het verleden liggen.',
            buttons: [
              {
                text: 'Sluiten',
                role: 'cancel'
              }
            ]
          })
          .then(a => a.present())
        return false
      }

      this.$set(this.clock_values, clock.id, value)
      this.$store.commit('setClock', {
        'id': clock.id,
        'value': dateValue.toISOString()
      })
      if (this.clocks.length > 1) {
        let allDone = true
        Object.keys(this.clock_values).forEach((loopClock) => {
          if (this.clock_values[loopClock] === null) {
            allDone = false
          }
        })

        if (allDone) {
          this.$router.push({
            'name': 'clock',
            'params': {
              'id': this.to
            }
          })
        }
      } else {
        this.$router.push({
          'name': 'clock',
          'params': {
            'id': this.to
          }
        })
      }
    },
    minDate (clock) {
      if (typeof clock.min_date_previous_id === 'undefined') {
        return this.min_date
      }

      for (const previousId of clock.min_date_previous_id) {
        const previousClockValue = this.$store.state.clocks[previousId]

        if (typeof previousClockValue !== 'undefined') {
          let tzoffset = (new Date()).getTimezoneOffset() * 60000
          const localISOTime = (new Date(new Date(previousClockValue) - tzoffset)).toISOString().slice(0, -1)
          return localISOTime
        }
      }

      return this.min_date
    }
  },
  mounted () {
    for (let clock in this.clocks) {
      this.$set(this.clock_values, this.clocks[clock].id, null)
    }
    setInterval(() => {
      // this.min_date = new Date(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).setDate(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).getDate() - 2)).toISOString()
      // this.max_date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().slice(0, -1)
    }, 5000)
  }
}
</script>

<style type="scss" scoped>
  ion-list {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  ion-list ion-item {
    cursor: pointer;
    --background: #f9f9f9
  }
  ion-list ion-item:first-child {
    border-top: 1px solid #DEDEDE;
  }
</style>
