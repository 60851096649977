import axios from 'axios'
import store from '../store'

axios.defaults.withCredentials = true

const axiosInstance = {}

// Routes
axiosInstance.routes = {
  login: '/auth/login',
  logout: '/auth/logout',
  forgot_password: '/auth/forgot_password',
  reset_password: '/auth/reset',
  setup_mfa: '/auth/setup_mfa',
  validate_mfa: '/auth/validate_mfa',
  getUser: '/auth/me'
}

// Instances
const instance = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  headers: {
    'Authorization': 'Bearer ' + store.state.access_token,
    'Content-Type': 'application/json'
  }
})

instance.interceptors.response.use(
  response => response,
  err => {
    console.log('Axios run interceptors')

    if (err.response.status === 401 && window.location.href.match(/^\/login\/.+$/)) {
      axiosInstance.logout(false)

      window.location.href = '/login?session_expired=true'
    }

    return Promise.reject(err.response)
  }
)

axiosInstance.post = (url, data, config = {}) => {
  console.log('Axios run POST', url)

  return instance.post(process.env.VUE_APP_API_HOST + url, data, config).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosInstance.get = (url, query = '') => {
  console.log('Axios run GET', url)

  return instance.get(process.env.VUE_APP_API_HOST + url, query).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosInstance.put = (url, data) => {
  console.log('Axios run PUT', url)

  return instance.put(process.env.VUE_APP_API_HOST + url, data).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosInstance.patch = (url, data) => {
  console.log('Axios run PATCH', url)

  return instance.patch(process.env.VUE_APP_API_HOST + url, data).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

axiosInstance.delete = (url) => {
  console.log('Axios run delete', url)

  return instance.delete(process.env.VUE_APP_API_HOST + url).then(response => {
    return response
  }).catch(err => {
    return err
  })
}

// Manual functions
axiosInstance.login = (username, password) => {
  console.log('Axios run login')

  axiosInstance.logout(false)

  return axios.post(process.env.VUE_APP_API_HOST + axiosInstance.routes.login, {
    'username': username.toLowerCase(),
    'password': password
  }, {
    headers: {
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    store.commit('setAccessToken', response.data.access_token)

    instance.defaults.headers.Authorization = 'Bearer ' + response.data.access_token

    return response
  }).catch(err => {
    axiosInstance.logout()

    if (typeof err.response !== 'undefined') {
      return err.response
    } else {
      return err
    }
  })
}

axiosInstance.autoLogin = async (token) => {
  console.log('Axios run autoLogin')

  axiosInstance.logout(false)

  store.commit('setAccessToken', token)

  instance.defaults.headers.Authorization = 'Bearer ' + token

  const me = await axiosInstance.getUser()
  return me
}

axiosInstance.setup_mfa = (secret, code) => {
  console.log('Axios run setup mfa')

  return axios.post(process.env.VUE_APP_API_HOST + axiosInstance.routes.setup_mfa, {
    'secret': secret,
    'code': code
  }, {
    headers: {
      'Authorization': 'Bearer ' + store.state.access_token,
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    store.commit('setAccessToken', response.data.result.access_token)
    instance.defaults.headers.Authorization = 'Bearer ' + response.data.result.access_token

    const me = await axiosInstance.getUser()
    return me
  }).catch(err => {
    if (typeof err.response !== 'undefined') {
      return err.response
    } else {
      return err
    }
  })
}

axiosInstance.validate_mfa = (code) => {
  console.log('Axios run validate mfa')

  return axios.post(process.env.VUE_APP_API_HOST + axiosInstance.routes.validate_mfa, {
    'code': code
  }, {
    headers: {
      'Authorization': 'Bearer ' + store.state.access_token,
      'Content-Type': 'application/json'
    }
  }).then(async response => {
    store.commit('setAccessToken', response.data.result.access_token)
    instance.defaults.headers.Authorization = 'Bearer ' + response.data.result.access_token

    const me = await axiosInstance.getUser()
    return me
  }).catch(err => {
    if (typeof err.response !== 'undefined') {
      return err.response
    } else {
      return err
    }
  })
}

axiosInstance.getUser = () => {
  console.log('Axios run getUser')

  return axiosInstance.get(axiosInstance.routes.getUser).then(response => {
    if (JSON.stringify(response.data.result) !== JSON.stringify(store.getters.getUser)) {
      store.commit('setUser', response.data.result)
    }

    window.loginTimer = setTimeout(() => axiosInstance.getUser(true), 30000)

    return response
  }).catch(err => {
    axiosInstance.logout()

    window.location.href = '/login'

    if (typeof err.response !== 'undefined') {
      return err.response
    } else {
      return err
    }
  })
}

axiosInstance.logout = async function () {
  console.log('Axios run logout')

  clearTimeout(window.loginTimer)
  window.loginTimer = null

  store.commit('setUser', {})
  store.commit('setAccessToken', null)

  delete instance.defaults.headers.Authorization

  return true
}

export { axios, axiosInstance }
