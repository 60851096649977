<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>
    <ion-content>
      <h1>Actuele studies</h1>
      <div>
        <pdf-modal name='Actuele studies' attachment='20241028-overzicht-acute-vasculaire-trials.pdf'>Klik hier voor actuele studies</pdf-modal>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  name: 'studies'
}
</script>

<style type="scss" scoped>
  li {
    line-height: 30px;
  }
</style>
