<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>

    <!-- autologin failed -->
    <ion-content v-if="autoLoginFailed">
      <ion-grid>
        <ion-row justify-content-center>
          <ion-col size="10">
            <h1>Inloggen mislukt</h1>
            <p>De meegegeven token is niet geldig.</p>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  name: 'AutoLogin',
  data () {
    return {
      autoLoginFailed: false
    }
  },
  methods: {
    async validateAutoLogin () {
      if (typeof this.$route.params.token === 'undefined' || this.$route.params.token === '') {
        this.autoLoginFailed = true
        return false
      }

      const auth = await this.$axios.autoLogin(this.$route.params.token)

      if (typeof auth.status !== 'undefined' && auth.status === 200) {
        this.$router.push({ name: 'home' })
      } else {
        this.autoLoginFailed = true
      }
    }
  },
  mounted () {
    this.validateAutoLogin()
  }
}
</script>

<style lang="scss" scoped>

</style>
