<template>
  <ion-page class="ion-page">
    <custom-header></custom-header>
    <ion-content v-if="screens !== null">
      <ion-grid>
        <ion-row align-items-start>
          <ion-col>
            <h1>{{ screens.title}}</h1>
            <component :answers="screens.answers" :is="compiledScreen"></component>
          </ion-col>
        </ion-row>
        <ion-row align-items-end>
          <ion-col>
            <ion-button expand="full" color="primary" @click="restartScreens" v-if="screens !== null && (typeof screens.answers === 'undefined' || screens.answers === null || screens.answers.length === 0)">
              Herstarten
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </ion-content>
    <ion-content v-else>
      <h1>Geen scherm gevonden</h1>
      <p>
        Er is geen scherm gekoppeld aan nummer {{ $route.params.id }}.
      </p>
    </ion-content>
    <ion-footer>
      <umcg-timebar />
    </ion-footer>
  </ion-page>
</template>

<script>
export default {
  name: 'screens',
  data () {
    return {
      'screens': null
    }
  },
  methods: {
    restartScreens () {
      this.$router.push(
        { name: 'home' }
      )
    }
  },
  computed: {
    compiledScreen () {
      return {
        props: ['answers'],
        template: `<div>${this.screens.description}</div>`
      }
    }
  },
  watch: {
    $route (to, from) {
      if (typeof this.$store.state.screens[to.params.id] !== 'undefined') {
        this.screens = this.$store.state.screens[to.params.id]
      } else {
        this.screens = null
      }
    }
  },
  mounted () {
    if (typeof this.$store.state.screens[this.$route.params.id] !== 'undefined') {
      this.screens = this.$store.state.screens[this.$route.params.id]
    } else {
      this.screens = null
    }
  }
}
</script>

<style type="scss" scoped>
  div {
    padding: 0;
  }
  ion-grid {
    height: 100%;
    display: flex;
    flex-flow: column;
    --ion-grid-padding: 0;
  }
  ion-grid ion-row:first-child {
    flex-grow: 1;
  }
  ion-grid ion-row ion-col {
    --ion-grid-column-padding: 0;
  }
</style>
