<template>
  <!-- forgot password -->
  <ion-page>
    <custom-header></custom-header>

    <!-- pw request form -->
    <ion-content v-if="passwordRequestPosted === false">
      <form @submit.prevent="requestPassword">
        <ion-grid>
          <ion-row justify-content-center>
            <ion-col size="10">
              <h1>Wachtwoord vergeten</h1>
              <ion-item>
                <ion-label position="stacked">E-mail</ion-label>
                <ion-input type="text" name="email" @ionChange="email = $event.target.value"></ion-input>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10" style="--ion-grid-column-padding: 20px;">
              <ion-button @click="$router.push({ name: 'login' })" type="button">Annuleren</ion-button>
              <ion-button type="submit" :disabled="passwordRequestButtonDisabled">Verzenden</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>

    <!-- pw request form succeeded -->
    <ion-content v-if="passwordRequestPosted === true">
      <form @submit.prevent="requestPassword">
        <ion-grid>
          <ion-row justify-content-center>
            <ion-col size="10">
              <h1 class="font-weight-normal border-bottom pb-2 mb-4">E-mail verzonden</h1>
              <p>Er is een e-mail naar u verstuurd. In het bericht zit een link waarmee u het wachtwoord opnieuw kunt instellen.</p>
              <p><strong>Let op:</strong> Alleen als het door u opgegeven e-mailadres bij ons bekend is, versturen we deze e-mail.</p>
            </ion-col>
          </ion-row>
          <ion-row justify-content-center>
            <ion-col size="10" style="--ion-grid-column-padding: 20px;">
              <ion-button @click="$router.push({ name: 'login' })" type="button">Inloggen</ion-button>
            </ion-col>
          </ion-row>
        </ion-grid>
      </form>
    </ion-content>
  </ion-page>
</template>

<script>
export default {
  name: 'ForgotPassword',
  data () {
    return {
      email: '',
      passwordRequestButtonDisabled: true,
      passwordRequestPosted: false
    }
  },
  methods: {
    requestPassword () {
      this.passwordRequestPosted = true

      let postData = {
        'email': this.email
      }

      this.$axios.post(this.$axios.routes.forgot_password, postData)
    }
  },
  watch: {
    email () {
      this.passwordRequestButtonDisabled = !(this.email !== '')
    }
  },
  mounted () {
    this.$axios.logout(false)
  }
}
</script>

<style lang="scss" scoped>

</style>
