<template>
  <ion-list v-if="answers !== null">
    <ion-item no-padding @click="$router.push({name: 'screen', params: { id: answer.to.toString() }})" v-for="(answer, index) in answers" :key="index">
      <ion-text>
        {{ answer.answer }}
      </ion-text>
    </ion-item>
  </ion-list>
</template>

<script>
export default {
  name: 'AnswerButtons',
  props: {
    answers: {
      type: Array,
      required: true,
      default: () => []
    }
  }
}
</script>

<style type="scss" scoped>
  ion-list {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  ion-list ion-item {
    cursor: pointer;
    --background: #f9f9f9
  }
  ion-list ion-item:first-child {
    border-top: 1px solid #DEDEDE;
  }
  ion-list ion-item ion-text {
    padding-left: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>
